@import 'src/styles/colors';
@import 'src/styles/functions';

.calendar {
  max-width: rem(360);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(20);

    &-btn {
      background-color: $white;
      border: rem(1) solid $grey;
      padding: rem(5) rem(10);
      border-radius: rem(5);
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover,
      &:disabled {
        background-color: $grey-light;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  &__weekdays {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(10);

    &__weekday {
      font-size: rem(14);
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: $grey-dark;
    }
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: rem(10);
  }

  &__day {
    font-size: rem(18);
    text-align: center;
    background-color: $white;
    border: rem(1) solid $grey;
    padding: rem(10);
    border-radius: rem(5);
    cursor: pointer;
    transition: background-color 0.2s;

    &.disabled {
      background-color: $grey-light;
      &:hover {
        cursor: not-allowed;
      }
    }

    &.selected {
      background-color: $blue;
    }

    &__day-empty {
      visibility: hidden;
    }
  }
}
