@import 'src/styles/fonts';

* {
  margin: 0;
  padding: 0;
}

.m-error-component {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $grey-light;

  &--404 {
    background-color: $yellow;
  }
}

.hero-container {
  margin-bottom: rem(30);

  &__content.img {
    min-width: rem(400);
  }

  &__content.text {
    font-size: rem(220);
    margin-top: rem(70);
    margin-bottom: rem(110);
    letter-spacing: rem(0.26);
  }
}

.error-message {
  text-align: center;
  word-wrap: break-word;
  margin-bottom: rem(40);
  max-width: 80vw;
}

.error-title {
  @include heading-1;
  margin-bottom: rem(10);
}

.error-description {
  font-size: rem(24);
  line-height: rem(26);
  letter-spacing: rem(0.04);
  font-weight: $font-light;
}
