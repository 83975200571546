@import 'src/styles/functions';
@import 'src/styles/colors';
@import 'src/styles/fonts';

.p-user {
  &__wrapper {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 12;
    align-items: flex-start;
    width: 100%;
  }

  &__action-wrapper {
    flex: 2;
    &__image {
      display: flex;
      margin-bottom: rem(30);
    }

    &__button {
      display: flex;
      flex-direction: column;
      gap: rem(20);
    }
  }

  &__row {
    margin-top: rem(6);
    display: flex;
    align-items: flex-start;
    margin: unset;
  }

  &__info-wrapper {
    flex: 8;
    margin-bottom: 50px;

    &__paragraph {
      font-size: rem(24);
      font-weight: lighter;
      margin-bottom: rem(21);
    }

    &__heading {
      margin: 0 0 rem(11) 0;
      font-size: rem(40);
    }

    &__blue-paragraph {
      color: $blue;
      font-weight: bold;
      font-size: rem(24);
    }
  }

  &__modal {
    .m-modal-header {
      span:nth-child(2) {
        font-size: rem(20);
      }
    }

    .m-modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: rem(30);
    }
  }
}

.p-dashboard__table-container {
  &-profile {
    overflow-x: auto;
    margin: rem(20) auto;

    .m-gourmet-table {
      width: 100%;

      &--header {
        tr {
          background-color: $grey-light;
        }
      }

      &--body {
        tr {
          &:hover {
            background: unset;
          }

          &:nth-of-type(even) {
            &:hover {
              background-color: $grey-light;
            }
          }
        }
      }

      th {
        @include label;

        color: $black;
        padding: 25px 8px;
      }

      td {
        @include paragraph;

        color: $black;

        @media (min-width: 768px) {
          padding: 25px 8px;
        }
      }
    }
  }
}

.p-dashboard__table-sort {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.p-dashboard__table-filters {
  display: flex;
  gap: rem(10);

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: auto;
  }
}

.p-dashboard__table-total-info {
  display: flex;
  gap: rem(10);
  margin-top: rem(10);
}

@media screen and (max-width: 1024px) {
  .p-user {
    &__wrapper {
      grid-column-start: 2;
      grid-column-end: 12;
      align-items: flex-start;
      width: 100%;
    }

    &__row {
      display: block;
    }

    &__action-wrapper {
      display: grid;
      justify-content: center;
      margin-bottom: 50px;
    }

    &__info-wrapper {
      &__heading {
        text-align: center;
      }

      &__blue-paragraph {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .p-user {
    &__wrapper {
      grid-column-end: 12;
      align-items: flex-start;
      width: 100%;
    }
    &__action-wrapper {
      grid-column-start: 1;
      grid-column-end: 12;
    }
    &__info-wrapper {
      margin-top: 5vh;
      grid-column-start: 1;
      grid-column-end: 12;
    }
  }

  .p-dashboard__table-sort {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .input-date-container {
      display: flex;
      flex-direction: column;
    }
  }
}
