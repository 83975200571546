@import 'src/styles/functions';
@import 'src/styles/colors';

.p-set-password {
  background-color: $blue;
  height: rem(930);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__container {
    background: $white;
    padding: rem(50);
    border-radius: rem(10);

    &__form {
      margin-top: rem(30);
      display: flex;
      flex-direction: column;
      gap: rem(20);
      align-items: flex-start;

      button:first-of-type {
        margin-right: rem(20);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .p-set-password__container {
    padding: rem(20);

    h1 {
      font-size: rem(30);
    }
  }
}
