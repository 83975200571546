@import 'src/styles/fonts';

$row-height: 100;
$table-border: 2px solid $blue;

.table {
  width: 100%;
}

.table__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(1, 1.5fr) repeat(1, 2fr) repeat(
      2,
      3fr
    );
  align-items: center;
  background-color: $white;

  &:nth-child(even) {
    background-color: $grey-light;
  }
}

.table__header {
  height: rem(50);
  @include label;
  line-height: rem(20);
}

.table__body {
  border-top: $table-border;
  border-bottom: $table-border;
  display: block;
  max-height: 50vh;
  overflow-y: scroll;
}

.table__body .table__cell.name,
.table__body .table__cell.email,
.table__body .table__cell.company {
  @include heading-6;
}

.table__body .table__row {
  min-height: rem(100);

  & * {
    box-sizing: border-box;
    margin: 0;
  }
}

.table__cell {
  position: relative;
  overflow: hidden;
  padding: 0 rem(20);

  &:before {
    @include label;
  }
}

.table__cell.buttons {
  display: flex;
  justify-content: center;
  gap: rem(10);
}

.cell__data {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;

  &:hover,
  &:focus {
    word-wrap: break-word;
    overflow: visible;
  }
}

@media (max-width: 1600px) {
  .table__header {
    display: none;
  }

  .table__body {
    max-height: 60vh;
  }

  .table__body .table__row,
  .table__cell {
    display: block;
    width: 100%;
  }

  .table__cell {
    text-align: right;
    position: relative;
    border: 1px solid $grey-light;
    padding: rem(10);
    padding-left: calc(50% - 20px);

    &:hover {
      cursor: default;
    }

    &:nth-child(odd) {
      border: 1px solid $grey;
    }

    &:before {
      content: attr(data-label);
      position: absolute;
      left: rem(20);
    }
  }
}
@media (max-width: 768px) {
  .table__cell {
    padding-left: 10px;
    text-align: left;
  }

  .table__cell:before {
    position: static;
  }
}
