@import 'src/styles/fonts';

.form-container {
  // this could be handled by Form component as
  // to avoid repetition and
  // to preserve consistency
  padding: rem(30) 10vw;
  h1 {
    margin-bottom: rem(50);
  }
}

.create-restaurant {
  display: flex;
  gap: rem(100);

  .left {
    flex-basis: rem(450);
    display: flex;
    flex-direction: column;
    gap: rem(50);

    .buttons {
      display: flex;
      gap: rem(20);
    }
  }
  .right {
    flex-basis: rem(830);
    display: flex;
    flex-direction: column;
    gap: rem(20);
  }
}
