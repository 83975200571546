@import 'src/styles/mixins';
@import 'src/styles/functions';

$devices: [ 'laptop', 'tablet', 'mobile', 'desktop' ];

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: rem(24);
  justify-items: center;
  align-items: center;
  margin: 0 auto;

  &__large {
    max-width: rem(1440);
  }

  &__medium {
    max-width: rem(1020);
  }

  &__small {
    max-width: rem(768);
  }

  .col {
    grid-column: span 12;
  }
}

@media screen and (min-width: 1441px) {
  .col {
    @include generateColumnSpan(desktop);
  }
}

@media screen and (max-width: 1440px) {
  .row {
    grid-column-gap: rem(15);
  }

  .col {
    @include generateColumnSpan(laptop);
  }
}

@media screen and (max-width: 1020px) {
  .row {
    grid-column-gap: rem(10);
  }

  .col {
    @include generateColumnSpan(tablet);
  }
}

@media screen and (max-width: 768px) {
  .row {
    grid-column-gap: rem(5);
  }

  .col {
    @include generateColumnSpan(mobile);
  }
}
