@import 'src/styles/fonts';

.p-restaurant {
  margin-top: rem(20);

  &__detail-wrapper {
    grid-column-start: 2;
    grid-column-end: 12;
    align-items: flex-start;
    width: 100%;

    .subtitle {
      margin-bottom: rem(10);
    }

    h2 {
      color: $blue;
      margin-top: rem(10);
    }
  }

  &__action-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    z-index: 1;
  }

  &__heading {
    margin-top: rem(80);

    p {
      grid-column-end: -1;
      font-size: rem(87);
      line-height: rem(42);
      letter-spacing: rem(0.1);
      color: $grey_light;
      font-weight: $font-bold;
    }
  }

  &__dropdown-menu {
    margin-top: rem(50);
    form {
      width: rem(200);
    }
  }

  &__menu-wrapper {
    margin-top: rem(30);
    display: flex;
    gap: rem(24);
  }

  &__categories {
    width: rem(220);
    align-self: start;
    display: flex;
    flex-direction: column;

    h4 {
      cursor: pointer;
      margin-bottom: rem(16);
      position: relative;
      padding: rem(8) 0;
    }

    .active:after {
      display: block;
      content: '';
      width: rem(77);
      height: rem(10);
      background: $blue-light;
      position: absolute;
      bottom: rem(1);
      z-index: -1;
    }
  }

  &__foods {
    .row {
      align-items: flex-start;
      > div {
        display: flex;
        height: 100%;
      }
    }
  }

  &__modal {
    .m-modal-header {
      span:nth-child(2) {
        font-size: rem(20);
      }
    }

    .m-modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: rem(30);
    }
  }

  @media screen and (max-width: 1550px) {
    .helper {
      margin: 0 rem(100);
    }

    &__categories {
      padding: rem(10) rem(10) rem(10) rem(30);
    }

    &__menu-items {
      margin-left: rem(10);
    }

    &__heading {
      margin-bottom: rem(50);
    }
  }

  @media screen and (max-width: 1440px) {
    &__detail-wrapper {
      margin-left: rem(18);
    }

    &__menu-wrapper {
      justify-content: space-evenly;
    }
  }

  @media screen and (max-width: 950px) {
    .helper {
      margin: 0 rem(50);
    }

    .image--large {
      width: rem(150);
      height: rem(150);
    }
  }

  @media screen and (max-width: 750px) {
    .helper {
      margin: 0 rem(50) rem(30);
      display: flex;
      flex-direction: column;
      gap: rem(10);
    }

    &__detail-wrapper {
      margin: 0 auto rem(30);
      text-align: center;
    }

    &__menu-wrapper {
      flex-direction: column;
      gap: rem(30);
    }

    &__categories {
      align-self: flex-start;
      flex-direction: row;
      padding: rem(20);
      margin-top: rem(20);
      gap: rem(20);
      width: 100%;
      overflow: auto;
      white-space: nowrap;
      box-sizing: border-box;
    }

    &__menu-items {
      margin: 0;
    }

    &__heading {
      margin: 0 auto;

      button {
        grid-column-start: 2;
        margin-left: rem(30);
      }

      p {
        grid-column-start: 3;
      }
    }
  }

  @media screen and (max-width: 500px) {
    &__heading {
      button {
        grid-column-start: 1;
        margin-left: rem(10);
      }
    }
    &__categories {
      h4 {
        font-size: rem(20);
      }

      .active:after {
        width: rem(57);
        height: rem(8);
        bottom: rem(3);
      }
    }
  }
}
