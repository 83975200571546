@import 'src/styles/fonts';

.button {
  @include label;
  // Icon imports
  --arrow-right: url('/svg/arrow-right.svg');
  --arrow-left: url('/svg/arrow-left.svg');
  --arrow-down: url('/svg/arrow-down.svg');
  --close: url('/svg/close.svg');

  // Default Button
  background-color: $blue;
  color: $black;

  height: rem(48);
  width: clamp(150px, rem(170), 190px);
  line-height: 0;

  border: rem(2) solid $black;
  border-radius: 100vh;

  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  // Button settings
  // Content
  &::before {
    content: attr(data-text);
  }
  &.icon {
    &--arrow-left {
      &::before {
        content: var(--arrow-left);
      }
    }

    &--arrow-right {
      &::before {
        content: var(--arrow-right);
      }
    }

    &--arrow-down {
      &::before {
        content: var(--arrow-down);
      }
    }

    &--close {
      &::before {
        content: var(--close);
      }
    }
  }

  // Color
  &.bg {
    &--black {
      background-color: $black;
      color: $white;
    }

    &--white {
      background-color: $white;
    }

    &--blue {
      background-color: $blue;
      color: $white;
    }

    &--red {
      background-color: $red;
    }

    &--green {
      background-color: $green;
    }
  }

  // Size
  &.large {
    width: clamp(200px, rem(220), 240px);
  }
  &.small {
    width: clamp(90px, rem(98), 105px);
  }
  &.slim {
    height: rem(24);
    font-size: rem(12);
    width: clamp(120px, rem(140), 160px);

    &.small {
      height: rem(24);
      width: clamp(90px, rem(98), 105px);
    }

    &.large {
      height: rem(24);
      width: clamp(200px, rem(220), 240px);
    }
  }

  // Hover Button settings
  &:hover {
    // Content
    &::before {
      content: attr(data-hover-text);
    }
    &.hover-icon {
      &--arrow-left {
        &::before {
          content: var(--arrow-left);
        }
      }
      &--arrow-right {
        &::before {
          content: var(--arrow-right);
        }
      }
      &--arrow-down {
        &::before {
          content: var(--arrow-down);
        }
      }
      &--close {
        &::before {
          content: var(--close);
        }
      }
    }

    // Color
    &.hover-bg {
      &--black {
        background-color: $black;
        color: $white;
      }

      &--white {
        background-color: $white;
        color: $black;
      }

      &--red {
        background-color: $red;
        color: $white;
      }

      &--green {
        background-color: $green;
        color: $black;
      }
    }

    .hide-on-hover {
      display: none;
    }
  }

  // Modifiers
  &.no-border {
    border: none;
  }
  &.square {
    border-radius: rem(4);
    height: rem(40);
    width: clamp(100px, rem(112), 120px);

    &.large {
      width: rem(138);
    }
  }
}
