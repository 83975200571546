@import 'src/styles/functions';
@import 'src/styles/colors';

.m-gourmet-table {
  box-shadow: 0 0 rem(20) rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  border-collapse: collapse;
  margin: 0 auto;
  overflow-x: auto;
  width: 85%;
  th,
  td {
    padding: 8px;
    text-align: left;
    max-width: 600px;
  }

  &--header {
    tr {
      background-color: $blue;
      color: $white;
      border-bottom: 1px solid $black;
      flex: auto;
    }
  }

  &--body {
    tr {
      transition: background 0.25s ease;
    }
    &--cell-header {
      display: none;
    }
    tr:hover {
      background: $green;
    }
    tr:nth-of-type(even) {
      background-color: $grey-light;
    }
    tr:nth-of-type(even):hover {
      background: $green;
    }

    tr:last-of-type {
      border-bottom: 2px solid $blue;
    }
  }
}

@media screen and (max-width: 600px) {
  th {
    display: none;
  }

  .m-gourmet-table--body--cell-header {
    display: block;
    font-weight: bold;
    padding-right: 20px;
  }

  td {
    display: flex;
    justify-content: space-between;
    white-space: normal;
  }
}
