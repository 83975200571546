@import 'src/styles/fonts';
@import 'src/styles/functions';
@import 'src/styles/colors';

$row-height: 100;
$table-border: 2px solid $blue;

.category-row {
  .table {
    width: 100%;
    margin-bottom: 50px;

    &__button-group {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 50px;
    }
  }

  .table__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    background-color: $white;

    &:nth-child(even) {
      background-color: $grey-light;
    }
  }

  .table__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    background-color: $grey-light;
    padding: rem(10);

    .table__cell {
      padding: 0;
    }
  }

  .table__body {
    border-top: $table-border;
    border-bottom: $table-border;
    display: block;
    max-height: 50vh;
    overflow-y: auto;
  }

  .table__body .table__cell.name,
  .table__body .table__cell.email,
  .table__body .table__cell.company {
    @include heading-6;
  }

  .table__body .table__row {
    min-height: rem(100);
  }

  .table__cell {
    position: relative;
    overflow: hidden;
    padding: 0 rem(20);

    &.name {
      padding-left: 0;
    }

    &:before {
      @include label;
    }
  }

  .table__cell.buttons {
    display: flex;
    justify-content: flex-start;
    gap: rem(10);
  }

  .cell__data {
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;

    &:hover,
    &:focus {
      word-wrap: break-word;
      overflow: visible;
    }
  }

  @media (max-width: 1600px) {
    .table__body {
      max-height: 60vh;
    }

    .table__cell {
      text-align: left;
      position: relative;
      border: none;
      padding: rem(10);

      &:hover {
        cursor: default;
      }

      &:before {
        content: none;
      }
    }
  }

  @media (max-width: 1100px) {
    .table {
      margin-bottom: 50px;
    }

    .table__header {
      display: none;
    }

    .table__body .table__row,
    .table__cell {
      display: block;
      width: 100%;
    }

    .table__cell {
      text-align: right;
      position: relative;
      border: 1px solid $grey-light;
      padding: rem(10);
      padding-left: calc(50% - 20px);

      &.buttons {
        padding-left: rem(10);
        justify-content: space-between;
      }

      &:hover {
        cursor: default;
      }

      &:nth-child(odd) {
        border: 1px solid $grey;
      }

      &:before {
        content: attr(data-label);
        position: absolute;
        left: rem(20);
      }
    }
  }

  @media (max-width: 768px) {
    .table__cell {
      padding-left: 10px;
      text-align: left;

      &.buttons {
        padding-left: 0;
        justify-content: flex-start;
      }

      &.name {
        padding-left: 10px;
      }
    }

    .table__cell:before {
      position: static;
    }
  }
}

.p-restaurant__modal {
  position: absolute;
}
