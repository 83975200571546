@import 'src/styles/functions';
@import 'src/styles/colors';

.p-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__heading {
    font-size: rem(70);
    letter-spacing: rem(0.08);
    margin: rem(100) auto;

    span {
      display: block;
      margin-top: rem(15);
      color: $blue;
    }
  }

  &__table-container {
    overflow-x: auto;
    margin-bottom: rem(20);
  }

  button {
    font-size: rem(25);
    height: rem(75);
    width: rem(280);
  }
}

@media screen and (max-width: 600px) {
  .p-dashboard {
    text-align: center;

    &__heading {
      font-size: rem(40);
      margin-bottom: rem(50);
    }

    span {
      margin-top: rem(-10);
    }

    button {
      font-size: rem(16);
      height: rem(50);
      width: rem(180);
    }
  }
}
