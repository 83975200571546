@import 'src/styles/functions';
@import 'src/styles/colors';

.p-category-form {
  margin-top: rem(100);
  display: flex;
  flex-direction: column;
  gap: rem(50);
  align-items: center;

  &__heading {
    margin-bottom: rem(50);
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(30);

    .a-select-input {
      width: rem(360);
    }
  }

  &__alert {
    max-width: rem(360);
    margin-top: rem(-40);
    display: flex;
    align-items: center;
    gap: rem(10);

    .alert-circle {
      flex-shrink: 0;
    }

    p {
      color: $red;
    }
  }

  &__button-group {
    display: flex;
    gap: rem(130);

    button:disabled {
      cursor: not-allowed;
      background-color: $grey-light;
    }

    .hidden {
      visibility: hidden;
    }
  }
}
