@import 'src/styles/functions';
@import 'src/styles/colors';

.login-wrapper {
  background-color: $blue;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  @media (min-width: rem(781)) {
    min-height: calc(100vh - rem(100px));
  }

  .login-container {
    max-width: rem(576);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(20);
    background-color: $white;

    img {
      transform: translateY(rem(-65));
      margin-bottom: rem(-57);
      max-width: 350px;
    }

    h1 {
      margin: 0;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: rem(40);

      .a-input-field {
        height: rem(73);
      }

      &__actions {
        text-align: center;
        margin: rem(20);
        padding-bottom: rem(195);
        color: $blue;

        a,
        button {
          line-height: rem(24);
          letter-spacing: rem(0.02);
          font-size: rem(16);
          color: $blue;
        }

        a {
          text-decoration: none;
          display: block;
        }

        button {
          padding: 0;
          border: none;
          background-color: white;
          cursor: pointer;
          margin: rem(10);
        }
      }
    }

    button {
      margin-top: rem(10);
      align-self: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .login-wrapper {
    .login-container {
      h1 {
        font-size: rem(30);
      }
    }
  }
}
