@import 'src/styles/functions';
@import 'src/styles/colors';

.image {
  border-radius: 50%;
  object-fit: cover;

  &--small {
    height: rem(60);
    width: rem(60);
  }

  &--medium {
    height: rem(132);
    width: rem(132);
    border: rem(3) solid $black;
  }

  &--large {
    height: rem(212);
    width: rem(212);
    border: rem(4) solid $black;
  }

  &--extra-large {
    height: rem(392);
    width: rem(392);
    border: rem(4) solid $black;
  }
}

.image--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey-light;

  svg {
    max-width: 75%;
  }
}
