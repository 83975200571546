@import 'src/styles/fonts';

.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  transform: translateY(-10%);
  overflow: hidden;
  text-overflow: ellipsis;

  &__label {
    @include paragraph;
    text-transform: none;
    margin-bottom: rem(15);
  }

  &__check {
    position: relative;
    appearance: none;
    width: rem(32);
    height: rem(32);
    background-color: $white;
    border-radius: rem(7);
    border: rem(2) solid $black;
    outline: none;
    cursor: pointer;

    &:checked {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: url('/svg/check-icon.svg');
        line-height: 0;
      }
    }
  }
}
