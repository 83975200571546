@import 'src/styles/functions';

.p-account-request {
  grid-column-start: 2;
  grid-column-end: 12;
  width: 100%;

  &__main-heading {
    margin-bottom: rem(40);
  }

  &__link {
    margin-top: rem(43);
    display: flex;
    justify-content: center;
  }
}
