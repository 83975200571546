@import 'src/styles/functions';
@import 'src/styles/colors';

.loading-spinner-container {
  backdrop-filter: blur(6px);
  position: fixed;
  top: rem(100);
  left: 0;
  height: calc(100vh - 100px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  .loading-spinner {
    width: rem(50);
    height: rem(50);
    border: rem(10) solid $blue-light;
    border-top: rem(10) solid $blue;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
