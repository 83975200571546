@import 'src/styles/fonts';

.a-input-field {
  position: relative;
  display: inline-block;
  text-align: left;

  .show-hide {
    stroke: $black;
  }

  &__label {
    &--standard,
    &--outlined {
      display: block;
      line-height: 1.063;
      letter-spacing: rem(0.02);
      margin: 0 0 rem(8) rem(16);
    }

    &--filled {
      display: inline-block;
      width: rem(220);
      color: $grey-dark;
      text-transform: uppercase;
      font-size: rem(16);
      letter-spacing: rem(2.4);
      font-weight: $font-bold;
      line-height: 1.188;
      padding: rem(13) 0 rem(4);
      margin-right: rem(24);
    }
  }

  &__input {
    border: none;
    color: $black;
    font-size: rem(14);
    letter-spacing: rem(0.02);
    margin-bottom: rem(10);
    min-width: 275px;

    &:focus {
      outline: none;

      &::placeholder {
        color: transparent; // auto-hide placeholder text upon focus
      }
    }

    &--outlined[aria-invalid='true'] ~ .show-hide {
      stroke: $red;
    }

    &--standard[aria-invalid='true'],
    &--outlined[aria-invalid='true'] {
      -webkit-text-fill-color: $red;
      border: 2px solid $red;
      color: $red;
    }

    &--normal-grey[aria-invalid='true'],
    &--long-black[aria-invalid='true'],
    &--long-grey[aria-invalid='true'],
    &--filled[aria-invalid='true'] {
      -webkit-text-fill-color: $red;
      border-bottom: 2px solid $red;
      color: $red;
    }

    &--standard,
    &--outlined {
      border: 2px solid $black;
      border-radius: rem(24);
      line-height: 1.143;

      &::placeholder {
        font-size: rem(14);
        color: $grey;
      }
    }

    &--standard {
      width: rem(295);
      padding: rem(14);
    }

    &--outlined {
      width: rem(263);
      padding: rem(14) rem(46) rem(14) rem(14);
    }

    &--normal-grey,
    &--long-black,
    &--long-grey {
      // Implement better solution
      @media screen and (min-width: 480px) {
        width: rem(420);
      }
      border-bottom: 2px solid $black;
      font-size: rem(24);
      font-weight: 300;
      letter-spacing: rem(0.04);
      padding: 0;
      line-height: 1.4168;

      &::placeholder {
        color: $black;
        letter-spacing: rem(2.4);
        font-size: rem(16);
        font-weight: $font-bold;
        text-transform: uppercase;
      }
    }

    &--normal-grey {
      padding-bottom: rem(6);
      height: rem(28);

      &::placeholder {
        color: $grey-dark;
      }
    }

    &--long-black,
    &--long-grey {
      height: rem(28);
      padding-bottom: rem(6);
    }

    &--filled {
      cursor: pointer;
      background: transparent;
      line-height: 1.1335;
      // Implement better solution
      @media screen and (min-width: 480px) {
        width: rem(420);
      }
      font-size: rem(30);
      font-weight: $font-bold;
      letter-spacing: rem(0.04);
      padding: 0 rem(46) 0 0;
      border-bottom: 2px solid $black;
    }

    &--long-grey {
      &::placeholder {
        color: $grey-dark;
      }
    }
  }

  &__input:read-only {
    cursor: not-allowed;
    background: $grey-light;
  }

  &__error-message {
    display: flex;
    align-items: center;
    margin: rem(0) rem(0) rem(0) rem(16);
    color: $red;
    text-align: left;
    font-size: rem(12);
    font-weight: $font-bold;
    line-height: 1.25;
    max-width: 320px;

    span {
      margin-right: rem(11);
    }
  }

  &__input--filled ~ svg {
    position: relative;
    cursor: pointer;
    margin-left: rem(-20);
    z-index: -1;
  }

  &__input--filled ~ &__error-message {
    margin: rem(0) rem(0) rem(0) rem(260);
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
