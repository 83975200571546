@import 'fonts';

* {
  font-family: 'Helvetica Neue', sans-serif;
}

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}

h5 {
  @include heading-5;
}

h6 {
  @include heading-6;
}

.subtitle {
  @include subtitle;
}

p {
  @include paragraph;
}

label {
  @include label;
}

.disclaimer {
  @include disclaimer;
}
