@import 'src/styles/functions';

.add-menu-item-form {
  h1 {
    margin-bottom: rem(50);
  }

  &__container {
    display: flex;
    gap: rem(200);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(50);
  }

  &__checkbox-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(50);

    .checkbox-group {
      width: rem(464);
    }
  }

  &__button-wrapper {
    margin: rem(40) 0 rem(50);
    display: flex;
    gap: rem(24);
  }
}

@media screen and (max-width: 1440px) {
  .add-menu-item-form {
    &__container {
      gap: rem(100);
    }
  }
}

@media screen and (max-width: 1100px) {
  .add-menu-item-form {
    &__container {
      flex-direction: column;
      gap: rem(50);
    }
  }
}
