@import 'src/styles/fonts';

.m-restaurant {
  width: rem(342);
  height: rem(204);
  background-color: $grey-light;
  box-shadow: 0 3px rem(6);
  border-radius: rem(15);
  border: 3px solid $black;
  position: relative;
  margin-top: rem(69);
  margin-bottom: rem(52);

  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    width: rem(138);
    height: rem(138);
    border-radius: 50%;
    border: rem(3) solid $black;
    transform: translate(-50%, -50%);
  }

  &__title {
    margin-top: rem(74);
    margin-bottom: rem(17);
    text-align: center;

    &--color {
      color: $grey-dark;
    }
  }

  &__action-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__action-wrapper > *:not(:last-child) {
    margin-bottom: rem(17);
  }

  .status-bar {
    background-color: $white;
    min-width: clamp(140px, rem(180), 220px);
    max-height: rem(20);
    border: rem(2) solid $black;
    border-radius: rem(100);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0 rem(10);

    cursor: pointer;

    .text::before {
      @include label;
      content: attr(data-text-unavailable);
      font-size: rem(9);
      letter-spacing: rem(1.35);
      transition: all 2s linear;
    }

    .indicator {
      position: relative;
      width: rem(54);
      height: rem(6);
      border-radius: rem(100);
      background-color: $red;
    }

    &.active {
      .text::before {
        content: attr(data-text-available);
      }
      .indicator {
        background-color: $green;
      }
    }
  }
}
