@import 'src/styles/colors';

.banner__button-close {
  display: flex;
  cursor: pointer;
}

.a-select-input--company {
  margin-bottom: 20px;
  min-width: 275px;
  text-align: left;

  @media screen and (min-width: 480px) {
    width: 420px;
  }

  label {
    color: $grey-dark;
  }
  select {
    border-color: #000000;
    border-bottom: 2px solid;
    padding: 5px;
  }
}
