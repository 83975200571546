@import 'src/styles/fonts';

.input-date-container {
  @include heading-5;

  &__title {
    @include label;
    margin-bottom: rem(5);
    text-align: center;
  }

  &__inputs {
    .input-date {
      &__input {
        @include heading-5;
        display: block;
        max-width: rem(170);
        height: rem(50);
        background-color: $grey;
        border: 2px solid $grey-dark;
        padding: 0 rem(10);
        border-radius: rem(2);

        &:not(:placeholder-shown) {
          border-color: $black;
          outline: none;
        }

        &:placeholder-shown {
          border-color: $grey-dark;
        }

        &:focus,
        &:not(:placeholder-shown) {
          border-color: $black;
          outline: none;
        }
      }

      &::after {
        @include paragraph;
        display: block;
        content: attr(data-label);
        padding-top: rem(10);
        color: $grey-dark;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='date'] {
      -moz-appearance: textfield;
    }
  }
}
