@import 'src/styles/fonts';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(20);
  font-size: rem(24);
  letter-spacing: rem(3.6);
  line-height: rem(28);
  margin-top: rem(22);

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  ul {
    width: rem(200);
    list-style: none;
    display: flex;
    justify-content: space-around;
    font-weight: $font-bold;
    color: $grey-dark;

    li {
      min-width: rem(30);
      text-align: center;
      cursor: pointer;
    }
  }

  &__item--active {
    color: $black;
  }

  &__icon-inactive {
    visibility: hidden;
  }
}
