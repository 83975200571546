//Global styles

$blue: #00bdfa;
$blue-light: #33cafb;
$black: #000000;
$grey-dark: #909090;
$grey-light: #e2e2e2;
$grey: #c3c3c3;
$white: #ffffff;
$green: #0dfdb4;
$red: #ea4c5c;
$yellow: #ffdf77;
