@import 'src/styles/functions';

.content-start {
  justify-self: start;
  grid-column-start: 2;
  grid-column-end: 6;
}

.content-end {
  justify-self: right;
  grid-column-start: 7;
  grid-column-end: 12;
}

.p-all-users ~ h4 {
  text-align: center;
  margin-top: rem(100);
}
