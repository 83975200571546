@import 'src/styles/functions';

.m-token-modal {
  .m-modal-header {
    span:nth-child(2) {
      font-size: rem(20);
    }
  }

  .m-modal-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: rem(30);
  }
}
