@import 'src/styles/fonts';

.textarea {
  display: flex;
  flex-direction: column;
  max-width: rem(464);
  margin-bottom: rem(20);

  &__label {
    margin-bottom: rem(10);
    text-align: left;
  }

  &__input {
    resize: none;
    font-size: rem(16);
    font-weight: $font-regular;
    letter-spacing: rem(0.02);
    line-height: 1.434;
    padding: rem(12) rem(20);
    border: rem(2) solid $black;
    border-radius: rem(3);
    margin-bottom: rem(5);

    &:disabled {
      background-color: $grey;
      color: $grey-dark;
    }

    &:focus {
      outline: none;
    }
  }

  &__char-count {
    margin-left: auto;
    font-size: rem(12);
    color: $grey-dark;
  }

  &__footer {
    display: flex;
  }
}
