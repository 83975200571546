@import 'src/styles/fonts';

.m-card {
  position: relative;
  text-align: center;
  display: inline-block;
  background-color: $white;
  border-radius: rem(4);
  box-shadow: 0 rem(3) rem(6) rgba($black, 0.29);
  margin: rem(22) 0;

  &__content {
    display: flex;
    align-items: center;
    height: rem(90);
    gap: rem(23);
    padding: rem(14) rem(22) rem(23);
  }

  &__info {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: rem(3);
    width: rem(209);
    word-break: break-word;
  }

  &__username,
  &__title,
  &__email,
  &__company {
    margin: 0;
  }

  &__username {
    line-height: rem(28);
  }

  &__title,
  &__email,
  &__company {
    font-size: rem(12);
    line-height: rem(14);
  }

  &__email {
    color: $blue;
    opacity: 0.8;
  }

  &__company {
    color: $black;
    opacity: 0.8;
  }

  &__image {
    height: rem(60);
  }

  &__button {
    margin-bottom: rem(23);
  }

  &--activated {
    border: rem(3) solid $black;
  }

  &--deactivated {
    border: rem(3) solid $grey-dark;

    .m-card__button {
      visibility: hidden;
    }

    .m-card__info * {
      color: $grey-dark;
    }

    .m-card__image {
      opacity: 0.25;
    }
  }

  .status-bar {
    background-color: $green;
    max-height: rem(20);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    .text::before {
      @include label;
      content: attr(data-text-activate);
      font-size: rem(9);
      letter-spacing: rem(1.5);
    }
    &.active {
      background-color: $grey-light;
      .text::before {
        content: attr(data-text-deactivate);
      }
    }
  }
}
