@import 'src/styles/mixins';
@import 'src/styles/functions';

.user-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mq-laptop-helper {
    @media screen and (max-width: 1120px) {
      .col {
        @include generateColumnSpan(tablet);
      }
    }

    @media screen and (max-width: 768px) {
      .row {
        grid-column-gap: rem(5);
      }

      .col {
        @include generateColumnSpan(mobile);
      }
    }
  }
}
