@import 'src/styles/functions';
@import 'src/styles/colors';

.confirmation-wrapper {
  background-color: $blue;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .confirmation-container {
    margin-top: rem(-100);
    width: rem(400);
    min-height: rem(400);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(20);
    background-color: $white;
    padding: rem(75); // Adjust padding as needed
    border-radius: 3%;

    img {
      transform: translateY(rem(-133));
      margin-bottom: rem(-99);
    }

    h1 {
      margin: 0;
    }

    button {
      margin-top: rem(50);
      align-self: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .confirmation-wrapper {
    .confirmation-container {
      h1 {
        font-size: rem(30);
      }
    }
  }
}
