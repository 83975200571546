.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.gap-1 {
  gap: rem(16);
}
.gap-2 {
  gap: rem(24);
}
.gap-3 {
  gap: rem(32);
}
