@import 'src/styles/functions';
@import 'src/styles/colors';

.m-order-item {
  position: relative;
  display: inline-block;
  padding: rem(10);
  background-color: $grey-light;
  border-radius: rem(12);
  width: rem(340);
  min-height: rem(145);
  margin: rem(12) 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(51, 202, 251, 0.5);
  }

  &__name {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(5);
  }

  &__buttons {
    position: absolute;
    display: flex;
    gap: rem(10);
    bottom: rem(13);
    right: rem(13);

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      width: rem(20);
      height: rem(20);
    }

    button:nth-child(2) {
      display: flex;

      svg {
        flex: 1;
        height: rem(30);
        margin-top: rem(-3);

        tspan {
          fill: $blue-light;
          stroke: $black;
        }
      }
    }
  }
}

.m-selected-order-item {
  position: relative;
  display: inline-block;
  padding: rem(10);
  background-color: $blue-light;
  border-radius: rem(12);
  width: rem(340);
  min-height: rem(145);
  margin: rem(12) 0;

  &__name {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(5);
  }

  &__buttons {
    position: absolute;
    display: flex;
    gap: rem(10);
    bottom: rem(13);
    right: rem(13);

    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      width: rem(20);
      height: rem(20);
    }

    button:nth-child(2) {
      display: flex;

      svg {
        flex: 1;
        height: rem(30);
        margin-top: rem(-3);

        tspan {
          fill: $blue-light;
          stroke: $black;
        }
      }
    }
  }
}
