@import 'src/styles/functions';
@import 'src/styles/colors';
@import 'src/styles/fonts';

.popular-orders {
  &_container {
    margin-top: 40px;
    text-align: center;
  }

  &_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    margin: 0 20px;
    padding: 20px 0;
    gap: 20px;
  }

  &_card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: rem(80);
    border: rem(3) solid $grey-light;
    box-shadow: 0 rem(12) rem(24) rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: rgba(51, 202, 251, 0.5);
    }
  }

  &_title {
    margin: 50px auto 30px;
    text-transform: capitalize;
  }

  &_description {
    li {
      color: $blue-light;
      margin-top: rem(24);
      position: relative;
      font-size: rem(16);
      line-height: rem(20);
      list-style-type: none;
    }

    &-title {
      color: $blue-light;
      font-weight: bold;
    }
  }

  &_buttons-wrapper {
    position: absolute;
    top: rem(13);
    right: rem(13);
  }

  &_button {
    .p-dashboard & {
      cursor: pointer;
      border: none;
      background-color: transparent;
      width: rem(24);
      height: rem(24);
    }
  }

  @media (max-width: 1024px) {
    &_wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    &_wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
