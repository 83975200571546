@import 'src/styles/functions';
@import 'src/styles/colors';
@import 'src/styles/fonts';

.p-order {
  margin-top: rem(100);
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  gap: rem(50);
  align-items: center;

  &__heading {
    margin-bottom: rem(50);
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(30);

    .a-select-input {
      width: rem(360);
      margin-bottom: 20px;
    }
  }

  &__alert {
    max-width: rem(360);
    margin-top: rem(-40);
    display: flex;
    align-items: center;
    gap: rem(10);

    .alert-circle {
      flex-shrink: 0;
    }

    p {
      color: $red;
    }
  }

  &__button-group {
    display: flex;
    gap: rem(130);
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: rgba(226, 226, 226, 0.48);
    border-top: 2px solid #33cafb;
    height: 100px;
    align-items: center;

    button:disabled {
      cursor: not-allowed;
      background-color: $grey-light;
    }

    .hidden {
      visibility: hidden;
    }
  }

  &__categories {
    width: rem(220);
    align-self: start;
    display: flex;
    flex-direction: column;

    h4 {
      cursor: pointer;
      margin-bottom: rem(16);
      position: relative;
      padding: rem(8) 0;
    }

    .active:after {
      display: block;
      content: '';
      width: rem(77);
      height: rem(10);
      background: $blue-light;
      position: absolute;
      bottom: rem(1);
      z-index: -1;
    }
  }

  &__foods {
    .row {
      align-items: flex-start;
      > div {
        display: flex;
        height: 100%;
      }
    }
  }

  &__menu-wrapper {
    margin-top: rem(30);
    display: flex;
    gap: rem(24);
  }

  &__heading {
    p {
      grid-column-end: -1;
      font-size: rem(87);
      line-height: rem(42);
      letter-spacing: rem(0.1);
      color: $grey_light;
      font-weight: $font-bold;
    }
  }

  &__review {
    display: flex;
    flex-direction: column;
    max-width: rem(420);
    max-height: rem(900);
    padding: rem(80) rem(100) rem(60) rem(100);
    border: rem(3) solid $grey-light;
    box-shadow: 0 rem(12) rem(24) rgba(0, 0, 0, 0.06);

    ul {
      padding-left: rem(50);
      list-style: none;
    }
    li {
      color: $blue-light;
      margin-top: rem(20);
      position: relative;
      font-size: rem(16);
      line-height: rem(20);
      list-style-type: none;
    }
    h5 {
      margin-bottom: rem(20);
      text-align: center;
    }
    .date {
      text-align: center;
    }
  }

  @media screen and (max-width: 750px) {
    .helper {
      margin: 0 rem(50) rem(30);
      display: flex;
      flex-direction: column;
      gap: rem(10);
    }

    &__form {
      width: 100%;
    }

    &__detail-wrapper {
      margin: 0 auto rem(30);
      text-align: center;
    }

    &__menu-wrapper {
      flex-direction: column;
      gap: rem(30);
      width: 100%;
    }

    &__categories {
      align-self: flex-start;
      flex-direction: row;
      padding: rem(10);
      margin-top: rem(20);
      gap: rem(20);
      width: 100%;
      overflow: auto;
      white-space: nowrap;
      box-sizing: border-box;
    }

    &__menu-items {
      margin: 0;
    }

    &__heading {
      margin: 0 auto;

      button {
        grid-column-start: 2;
        margin-left: rem(30);
      }

      p {
        grid-column-start: 3;
      }
    }
  }

  @media screen and (max-width: 500px) {
    &__heading {
      button {
        grid-column-start: 1;
        margin-left: rem(10);
      }
    }
    &__categories {
      h4 {
        font-size: rem(20);
      }

      .active:after {
        width: rem(57);
        height: rem(8);
        bottom: rem(3);
      }
    }
  }
}

.textarea {
  &__label {
    text-align: center;
  }

  &__input {
    resize: none;
    font-size: rem(16);
    font-weight: $font-regular;
    letter-spacing: rem(0.02);
    line-height: 1.434;
    padding: rem(12) rem(20);
    border: rem(2) solid $black;
    border-radius: rem(3);
  }
}

.checkbox-group {
  &__title {
    @include label;
    margin-bottom: rem(5);
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(rem(100), 1fr));
    grid-row-gap: rem(20);
    grid-auto-flow: dense;
    border: rem(2) solid;
    border-radius: rem(3);
    padding: rem(28) rem(20px);
    background-color: $blue;
  }
}

.terms {
  &__content {
    padding: rem(20);
  }
  &__buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: rem(20);

    button {
      padding: 0 rem(10);
    }
  }
}
