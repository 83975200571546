@import 'src/styles/functions';

.create-new-user-form {
  display: flex;
  flex-direction: column;

  &__input-wrapper {
    display: flex;
    flex-direction: column;

    .a-input-field {
      height: rem(50);
    }
  }

  &__button-wrapper {
    display: flex;
    margin-top: 50px;
  }

  &__button-wrapper button:not(:last-child) {
    margin-right: 24px;
  }

  .a-input-field {
    height: 70px;

    &:first-child {
      margin-top: 24px;
    }
  }
}
