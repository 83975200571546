@import 'colors';
@import 'functions';

// FONT DEFINITIONS
$font-light: 200;
$font-regular: 400;
$font-bold: 700;

%font-bold-black {
  color: $black;
  font-weight: $font-bold;
}

@mixin heading-1 {
  @extend %font-bold-black;
  font-size: rem(40);
  letter-spacing: rem(0.05);
  line-height: rem(48);
}

@mixin heading-2 {
  @extend %font-bold-black;
  font-size: rem(36);
  letter-spacing: rem(0.04);
  line-height: rem(42);
}

@mixin heading-3 {
  @extend %font-bold-black;
  font-size: rem(30);
  letter-spacing: rem(0.04);
  line-height: rem(35);
}

@mixin heading-4 {
  @extend %font-bold-black;
  font-size: rem(27);
  letter-spacing: rem(0.03);
  line-height: rem(31);
}

@mixin heading-5 {
  @extend %font-bold-black;
  font-size: rem(24);
  letter-spacing: rem(0.03);
  line-height: rem(27);
}

@mixin heading-6 {
  color: $black;
  font-weight: $font-bold;
  font-size: rem(20);
  letter-spacing: rem(0.02);
  line-height: rem(22);
}

@mixin subtitle {
  color: $black;
  font-weight: 300;
  font-size: rem(24);
  letter-spacing: rem(0.04);
  line-height: rem(26);
}

@mixin paragraph {
  color: $black;
  font-weight: $font-regular;
  font-size: rem(16);
  letter-spacing: rem(0.02);
  line-height: rem(24);
}

@mixin label {
  @extend %font-bold-black;
  letter-spacing: rem(2.4);
  font-size: rem(16);
  line-height: rem(24);
  text-transform: uppercase;
}

@mixin disclaimer {
  color: $black;
  font-weight: $font-regular;
  font-size: rem(12);
  letter-spacing: rem(0.02);
  line-height: rem(16);
}
