@import 'src/styles/functions';
@import 'src/styles/colors';

.a-overlay {
  --current-state-color: #{$green};

  position: fixed;
  top: rem(8);
  right: rem(8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &--error {
    --current-state-color: #{$red};

    .a-overlay__popup-title,
    .a-overlay__popup-message {
      color: $white;
    }
  }

  &__popup {
    background-color: var(--current-state-color);
    display: flex;
    flex-direction: column;
    gap: rem(4);
    padding: rem(8) rem(12);
    border-radius: rem(4);
  }

  &__popup + &__popup {
    margin-top: rem(8);
  }
}
