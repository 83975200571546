@import 'src/styles/functions';
@import 'src/styles/colors';

.p-all-restaurants {
  &__heading {
    color: $black;
    justify-self: start;
    grid-column-start: 2;
    grid-column-end: 6;

    &--light {
      color: $grey-light;
    }
  }

  &__action {
    justify-self: end;
    grid-column-start: 7;
    grid-column-end: 12;
  }
}

.p-all-restaurants ~ h4 {
  margin-top: rem(100);
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .p-all-restaurants {
    &__heading {
      justify-self: stretch;
      text-align: center;
    }

    &__action {
      justify-self: end;
    }
  }
}

@media screen and (max-width: 1024px) {
  .p-all-restaurants {
    &__heading {
      justify-self: stretch;
      text-align: center;
      margin-bottom: rem(20);
    }

    &__action {
      grid-column-start: 1;
      grid-column-end: end;
      justify-self: center;
    }
  }
}
