@import 'src/styles/fonts';

.a-select-input {
  display: flex;
  flex-direction: column;
  gap: rem(10);

  select {
    font-size: rem(24);
    letter-spacing: rem(0.04);
    font-weight: $font-light;
    width: 100%;
    border: rem(2) solid $black;

    &:focus {
      outline: none;
    }
  }
}
