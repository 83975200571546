@import 'src/styles/functions';
@import 'src/styles/fonts';

.user-search-container {
  h1 {
    text-align: center;
  }
  &__filters {
    margin-top: rem(15);
    margin-bottom: rem(10);
    display: flex;
    justify-content: center;

    &__select {
      flex: 1;
      &__input {
        flex-direction: column;
        text-align: left;
        margin-bottom: rem(3);
        margin-right: rem(-10);

        select {
          @include heading-5;
          display: block;
          max-width: rem(250);
          height: rem(54);
          background-color: $grey;
          border: 2px solid $grey-dark;
          padding: 0 rem(10);
          border-radius: rem(2);

          &:not(:placeholder-shown) {
            border-color: $black;
            outline: none;
          }

          &:placeholder-shown {
            border-color: $grey-dark;
          }

          &:focus,
          &:not(:placeholder-shown) {
            border-color: $black;
            outline: none;
          }
        }
      }
    }
    &__input {
      display: flex;
      flex: 2;
      flex-direction: column;
      margin-bottom: rem(3);
      margin-right: rem(-10);
      input {
        @include heading-5;
        height: rem(54);
        background-color: $white;
        border: 2px solid $black;
        padding: 0 rem(10);
        border-radius: rem(2);
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .user-search-container__filters {
    max-width: 1024px;
  }
}
@media screen and (max-width: 1024px) {
  .user-search-container__filters {
    max-width: 850px;
  }
}
@media screen and (max-width: 764px) {
  .user-search-container__filters {
    display: block;
    justify-content: center;
    white-space: normal;

    h5 {
      display: none;
    }
    label {
      display: none;
    }

    &__select {
      display: flex;
      justify-content: center;
      margin-right: rem(10);
    }
  }

  .input-date-container {
    display: flex;
    justify-content: center;
  }
}
