@import 'src/styles/fonts';

.alert-circle {
  display: inline-block;
  width: rem(13);
  height: rem(13);
  text-align: center;
  border: 2px solid $red;
  border-radius: 50%;
  color: $red;
  font-size: rem(10);
  font-weight: $font-bold;
}
