@import 'src/styles/fonts';

.edit-profile {
  @media screen and (min-width: 768px) {
    margin-left: rem(240);
  }

  &__heading {
    margin: rem(57) rem(0) rem(52) rem(0);
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: rem(21);

    .a-input-field {
      height: rem(67);
    }

    .a-select-input {
      flex-direction: row;
      gap: rem(24);

      label {
        width: rem(220px);
        color: $grey-dark;
        letter-spacing: rem(2.4);
        font-weight: $font-bold;
        text-transform: uppercase;
      }

      select {
        font-size: 1.875rem;
        font-weight: 700;
        width: rem(418px);
      }
    }
  }

  &__image {
    margin: rem(16) 0 rem(54);
    display: flex;
    align-items: center;
    gap: rem(24);

    &__label {
      width: rem(220);
      color: $grey-dark;
      letter-spacing: rem(2.4);
      font-weight: $font-bold;
      text-transform: uppercase;
    }

    .container {
      cursor: pointer;
    }
  }

  &__action-buttons {
    display: flex;
    gap: rem(24);
    margin-bottom: rem(30);
  }
}
