@import 'src/styles/functions';
@import 'src/styles/colors';

$max-width: rem(536);
$max-width-order: rem(1018);
$margin: 2rem;

@mixin center-content() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-modal {
  text-align: center;
  width: clamp(150px, $max-width, 90vw);
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: $white;
  z-index: 1;
  translate: -50% 150%;
  transition: translate 250ms ease-in-out;
  max-height: 65%;
  overflow-y: auto;

  &--has-border {
    border: 2px solid $black;
  }

  &.visible {
    translate: -50% -50%;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: $black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-out;
  }

  &.visible ~ &__backdrop {
    opacity: 0.2;
    pointer-events: all;
  }
}

.m-modal-header {
  width: 100%;

  &__banner {
    @include center-content;
    min-height: rem(50);

    &--is-blue {
      background-color: $blue;
    }
  }

  &__icon-container {
    @include center-content();
    border-radius: 50%;
    cursor: pointer;
  }

  &__title {
    @include center-content();
    padding: 20px 0 30px 0;
  }
}

.m-modal-body {
  @include center-content;
  flex-direction: column;
  align-items: inherit;

  .input-container {
    margin-bottom: rem(10);
    display: flex;
    flex-direction: column;
    align-items: center;

    .a-input-field {
      height: rem(70);
      input {
        margin-bottom: rem(10);
      }
    }

    button:last-child {
      margin-bottom: rem(40);
    }

    &.buttons {
      gap: rem(25);
    }
  }
}

.m-modal-footer {
  &__disclaimer {
    @include center-content;
    height: rem(100);

    &__link {
      color: $blue;
      font-weight: bold;
      text-decoration: none;

      &:visited {
        color: $blue;
      }
    }

    &--has-alt {
      background-color: $blue;
      text-align: left;

      & .disclaimer {
        width: clamp(100px, 360px, 420px);
        margin: 0 10px;
        color: $white;
      }
    }
  }
}

.m-order {
  text-align: center;
  width: clamp(150px, $max-width-order, 90vw);
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: $white;
  z-index: 1;
  translate: -50% 150%;
  transition: translate 250ms ease-in-out;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;

  &--has-border {
    border: 2px solid $black;
  }

  &.visible {
    translate: -50% -50%;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: $black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-out;
  }

  &.visible ~ &__backdrop {
    opacity: 0.2;
    pointer-events: all;
  }
}

.m-order-header {
  width: 100%;

  &__banner {
    @include center-content;
    min-height: rem(50);

    &--is-blue {
      background-color: $blue;
    }
  }

  &__icon-container {
    @include center-content();
    border-radius: 50%;
    cursor: pointer;
  }

  &__title {
    @include center-content();
    padding: 50px 0;
  }
}

.m-order-body {
  @include center-content;
  flex-direction: column;
  align-items: inherit;

  .input-container {
    margin-bottom: rem(10);
    display: flex;
    flex-direction: column;
    align-items: center;

    .a-input-field {
      height: rem(70);
      input {
        margin-bottom: rem(10);
      }
    }

    button:last-child {
      margin-bottom: rem(40);
    }

    &.buttons {
      gap: rem(25);
    }
  }
}

.m-order-footer {
  &__disclaimer {
    @include center-content;
    height: rem(100);

    &__link {
      color: $blue;
      font-weight: bold;
      text-decoration: none;

      &:visited {
        color: $blue;
      }
    }

    &--has-alt {
      background-color: $blue;
      text-align: left;

      & .disclaimer {
        width: clamp(100px, 360px, 420px);
        margin: 0 10px;
        color: $white;
      }
    }
  }
}
