@import 'src/styles/fonts';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10vw;
  height: rem(100);

  &--login {
    background-color: $blue;

    &__logo:hover {
      position: relative;
      path {
        fill: $black;
      }

      &::after {
        @include label;
        content: attr(data-label);
        color: $black;
        position: absolute;
        left: 0;
        top: rem(350);
        translate: -10%;
        rotate: 90deg;
      }
    }
  }

  &:not(&--mobile) {
    @media (max-width: rem(780)) {
      box-sizing: border-box;
      position: absolute;
      z-index: 1;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(24);
      width: 100%;
      height: auto;
      padding: 10vw;
      background-color: $blue;

      &.navbar--visible {
        display: flex;
      }
    }
  }

  &--mobile {
    @media (min-width: rem(781)) {
      display: none;
    }
  }
}

.navbar__group {
  position: relative;
  display: flex;
  gap: rem(40);

  @media (max-width: rem(780)) {
    .navbar:not(.navbar--mobile) & {
      flex-direction: column;
      gap: rem(24);
      width: 100%;
    }
  }
}

.navitem {
  width: rem(30);
  height: rem(30);
  cursor: pointer;

  @media (max-width: rem(780)) {
    .navbar:not(.navbar--mobile) & {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &::after {
    content: attr(data-label);
    position: absolute;
    top: 50%;
    @include label;
    opacity: 0;
    transition: opacity 300ms ease-out;
    pointer-events: none;

    @media (max-width: rem(780)) {
      .navbar:not(.navbar--mobile) & {
        left: 0 !important;
        right: auto;
        top: auto;
        padding-left: rem(70);
        white-space: nowrap;
        color: #fff;
        opacity: 1;
        transform: none;
        translate: none !important;
      }
    }
  }

  &:hover::after {
    opacity: 1;
  }

  &--left {
    &::after {
      right: rem(-80);
      translate: 100% -50%;
    }
  }

  &--right {
    &::after {
      left: rem(-60);
      translate: -100% -50%;
    }
  }

  &--order {
    @media (max-width: rem(780)) {
      .navbar:not(.navbar--mobile) & {
        display: none !important;
      }
    }
  }
}
