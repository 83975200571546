@import 'src/styles/functions';

.show-hide {
  cursor: pointer;
  width: rem(16);
  height: rem(16);
  line-height: 1;
  position: absolute;
  left: rem(295);
  top: rem(42);
}
